import React from "react"
import RegisterLayout from "../../shared/components/molecules/register-layout/register-layout"
import useMobie from "../../shared/hooks/useMobile"
import useRegisterInfo from "../../shared/hooks/useRegister"
import ConfirmationStepContent from "../../components/organisms/confirmation-content/confirmation-content"
import Layout from "../../shared/components/organisms/layout/layout"
import SEO from "../../shared/components/atoms/seo/seo"
import "../../shared/styles/index.scss"

const ConfirmationStep = props => {
  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null)
  const isMobile = useMobie()
  const {registerInfo} = useRegisterInfo()

  return (
    <Layout activeMenu={5} shopButton={false} showHeader={false}>
      <SEO
        title={registerInfo.metaTitulo ? registerInfo.metaTitulo : ""}
        description={
          registerInfo.metaDescripcion
            ? registerInfo?.metaDescripcion?.metaDescripcion
            : ""
        }
        slug={registerInfo.slug ? registerInfo.slug : ""}
        lang="ES"
      />
      <RegisterLayout redirection="registro/">
        <ConfirmationStepContent
          setModalActive={setModalActive}
          isMobile={isMobile}
          title={registerInfo.tituloIntroductorio}
          registerInfo={registerInfo}
        />
      </RegisterLayout>
      {modalActive}
    </Layout>
  )
}

export default ConfirmationStep
