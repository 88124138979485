import React, { useEffect, useState } from "react"
import Button from "../../../shared/components/atoms/button/button"
import homeLink from "../../../shared/utils/siteUrl"
import RegisterService from "../../../shared/services/register-service"
import PerritoGraciasImg from "../../../shared/assets/images/dogs-success.png"
import ModalGeneric from "../../../shared/components/modals/modal-generic/modal-generic"
import { sessionStorage, localStorage } from "../../../shared/utils/storage"
import userSessionStorage from "../../../shared/utils/userSessionStorage"
import { useForm } from "react-hook-form"
import ReactCodeInput from "react-code-input"
import {
  FORMAT_NUMBER_LETTERS_PATTERN,
  successStatus,
} from "../../../shared/constants/appConstants"
import Loader from "react-loader-spinner"
import { DateTime } from "luxon"
import "./confirmation-content.scss"

const ThirdStepContent = props => {
  const { setModalActive, isMobile } = props

  /*Load data*/
  const [defaultData, setDefaultData] = useState(
    sessionStorage.getItem("infoRegister")
  )
  const PARAMETROS_URL =
    typeof window !== "undefined" && new URLSearchParams(window.location.search)

  /*Form controls*/
  const sesion = new userSessionStorage()
  const [registerView, setRegisterView] = useState(false)
  const [otpView, setOtpView] = useState(false)
  const [optValidated, setOtpValidated] = useState(false)
  const [cellPhoneContact, setCellPhoneContact] = useState(false)
  const [emailContact, setEmailContact] = useState(true)
  const [userId, setUserId] = useState(
    PARAMETROS_URL && PARAMETROS_URL.get("n")
  )
  const [codeOtp] = useState(PARAMETROS_URL && PARAMETROS_URL.get("c"))

  /*Control button service*/
  const [loader, setLoader] = useState(false)

  const { handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      emailContact: true,
    },
  })

  /*Modal error*/

  const closeModal = () => setModalActive(null)

  const openModal = (
    title = "Ocurrio un error inesperado",
    text = "Por favor intentalo mas tarde",
    type = "warning",
    _onClose
  ) =>
    setModalActive(
      <ModalGeneric
        title={title}
        text={text}
        type={type}
        onClose={() => {
          _onClose && _onClose()
          closeModal()
        }}
      />
    )

  /*On Submit forms*/

  const onFinishRegister = async () => {
    await sessionStorage.removeItem("infoRegister")
    await localStorage.setItem("isFromRegister",true) 
    window.location.href = `${homeLink}mi-cuenta/perfil/`
  }

  const consultInfoUser = () => {
    RegisterService.getUserInfoCode(userId)
      .then(res => {
        let userData = res.data
        if (userData.cellphone) {
          userData.cellphone = sesion.getNoPrefix(userData.cellphone)
        }
        setDefaultData(userData)
      })
      .catch(error => {
        openModal(
          "Ocurrio un error inesperado",
          error.userMessage,
          "warning"
          // () => onFinishRegister()
        )
      })
  }

  const onHandleOtp = value => {
    if (value.length === 6 && loader === false) {
      setLoader(true)
      let payload = {
        code: value,
        userId: userId,
      }

      RegisterService.postConfirmation(payload)
        .then(res => {
          if (res.status.toString() === successStatus.toString()) {
            handleOtpValid(res.data)
          } else {
            if (res.errorCode === "UEM020" || res.errorCode === "UEM019") {
              consultInfoUser()
              openModal(
                codeOtp ? "Tu link ha expirado" : "Código otp inválido",
                res.userMessage
              )
            } else {
              openModal("Ocurrio un error inesperado", res.userMessage)
            }
          }
        })
        .catch(error => console.log("Error otp", error))
        .finally(() => setLoader(false))
    }
  }

  const onResendOtp = _userName => {
    let cellPrefix = process.env.COUNTRY_PHONE_PREFIX
      ? process.env.COUNTRY_PHONE_PREFIX
      : "+57"

    let userName = _userName
      ? _userName && cellPhoneContact
        ? cellPrefix + _userName
        : _userName
      : cellPhoneContact
      ? cellPrefix + defaultData.cellphone
      : defaultData.email

    setLoader(true)
    let payload = {
      username: userName,
      userId: userId,
    }

    RegisterService.postResendCode(payload)
      .then(res => {
        if (res.status.toString() === successStatus.toString()) {
          openModal("Código enviado con éxito", res.userMessage, "success")
        } else {
          if (res.errorCode === "DIM013") {
            openModal(
              "Ocurrio un error inesperado",
              res.userMessage,
              "warning",
              () => {
                sessionStorage.removeItem("infoRegister")
                window.location.href = homeLink + "mi-cuenta/autenticacion"
              }
            )
          } else {
            openModal(
              "Ocurrio un error inesperado",
              res.userMessage,
              "warning",
              () => {
                setOtpView(true)
              }
            )
          }
        }
      })
      .catch(error => console.log("Error captcha", error))
      .finally(() => setLoader(false))
  }

  const handleOtpValid = data => {
    localStorage.setItem("TOKEN_INFO", data)
    sessionStorage.removeItem("infoRegister")
    setOtpValidated(true)
    setTimeout(
      () => {
        setOtpValidated(false)
        setOtpView(false)
        setRegisterView(true)
      },
      codeOtp && userId ? 0 : 2000
    )
  }

  const onSubmitConfirmation = data => {
    const verificationMode = "EMAIL"
    if (verificationMode && !codeOtp && !userId) {
      let initialPets = sessionStorage.getItem("initialPets")
      setLoader(true)

      const birthday = DateTime.local(
        parseInt(defaultData.year),
        parseInt(defaultData.month),
        parseInt(defaultData.day)
      )

      let payload = {
        ...defaultData,
        userId: defaultData.userId ? defaultData.userId : null,
        address: defaultData.direction ? defaultData.direction : null,
        addressDetail: defaultData.directionDetail
          ? defaultData.directionDetail
          : null,
        birthday: birthday.toString(),
        cellphone: process.env.COUNTRY_PHONE_PREFIX
          ? process.env.COUNTRY_PHONE_PREFIX + defaultData.cellphone
          : "+57" + defaultData.cellphone,
        city: defaultData.city ? parseInt(defaultData.city) : null,
        email: defaultData.email,
        gender: defaultData.gender,
        lastname: defaultData.lastname,
        name: defaultData.name,
        password: defaultData.password,
        pets: defaultData.pets,
        phcAccepted: 1,
        tycEmail: 1,
        tycSms: 1,
        verificationMode: verificationMode,
      }

      if (initialPets && initialPets !== undefined) {
        initialPets.forEach(pet => {
          pet.petId = pet.petId?.toString()
          if (
            payload.pets.find(
              _pet => _pet.petId === pet.petId && _pet.name === pet.name
            ) === -1 ||
            payload.pets.find(
              _pet => _pet.petId === pet.petId && _pet.name === pet.name
            ) === undefined
          ) {
            RegisterService.deletePet({
              petId: pet.petId,
              userId: defaultData.userId,
            }).then(res => {})
          } else {
            console.log("====================================")
            console.log("ENCONTRO A", pet)
            console.log("====================================")
          }
        })
      }

      RegisterService.postRegister(payload)
        .then(res => {
          if (res.status.toString() === successStatus.toString()) {
            setUserId(res.data)
            setOtpView(true)
          } else {
            let title = "Ha ocurrido un error"
            let text = res.userMessage
            openModal(title, text, "warning")
          }
        })
        .catch(error => {
          console.log(error)
          openModal()
        })
        .finally(() => setLoader(false))
    } else if ((codeOtp || userId) && emailContact) {
      onResendOtp(defaultData.email)
      setOtpView(true)
    } else if ((codeOtp || userId) && cellPhoneContact) {
      onResendOtp(defaultData.cellphone)
      setOtpView(true)
    }
  }

  useEffect(() => {
    if (codeOtp && userId) {
      onHandleOtp(codeOtp)
    } else if (userId) {
      consultInfoUser()
    } else if (
      !defaultData ||
      !defaultData.name ||
      !defaultData.email ||
      !defaultData.lastname ||
      !defaultData.gender ||
      !defaultData.cellphone
      // !defaultData.tycPhysicalContat
    ) {
      window.location.href = homeLink + "registro/"
    }
  }, [])

  return (
    <div className="f-confirmation-content">
      {registerView ? (
        <div className="f-success-register">
          <div className="f-success-content">
            <i className="fal fa-check-circle f-icon-success"></i>
            <h2 className="f-title--purple f-succes-title">
              ¡Gracias por registrarte!
            </h2>
            <p className="f-text-regular f-success-description">
              Haz completado el registro, ahora puedes acceder al portal de
              Petys
            </p>
            <Button
              className="f-primary-purple f-button-next"
              title="Finalizar"
              onClick={onFinishRegister}
            />
          </div>
          <figure className="f-success-image">
            <img src={PerritoGraciasImg} alt="Gracias por registrarte" />
          </figure>
        </div>
      ) : otpView ? (
        <div className="f-otp">
          <div className="f-otp__container">
            <div className="f-otp__content">
              <h1 className="f-otp__content__title">Confirma tu cuenta</h1>

              <h2 className="f-otp__content__title2">
                {cellPhoneContact
                  ? "Ingresa el código que te enviamos a tu Celular"
                  : "¡Te enviamos un correo!"}
              </h2>

              {cellPhoneContact ? (
                <div
                  className={
                    "f-otp__content__input " +
                    (optValidated ? " f-otp__content__input--success" : "")
                  }
                >
                  <ReactCodeInput
                    type="text"
                    fields={6}
                    pattern={FORMAT_NUMBER_LETTERS_PATTERN}
                    onChange={onHandleOtp}
                  />
                  {optValidated && (
                    <p className="f-otp__content__input__message">
                      <i className="far fa-check-circle"></i> Confirmación
                      exitosa
                    </p>
                  )}
                </div>
              ) : (
                <p
                  className={
                    "f-otp__content__resend" +
                    (loader ? " f-otp__content__resend--inactive" : "")
                  }
                >
                  Revisa tu bandeja de entrada y dale clic al link que te
                  enviamos para verificar tu cuenta. Si no encuentras el correo,
                  busca en tu carpeta de Spam o Correo no deseado. Y si
                  definitivamente no te llegó{" "}
                  <Button
                    className="f-btn-purple f-otp__content__resend__link"
                    typeButton="link"
                    title="haz clic para reenviar tu email"
                    onClick={onResendOtp}
                  />
                </p>
              )}

              <Button
                className={
                  "f-otp__content__button-back" +
                  (isMobile ? " f-primary-outline-purple" : "")
                }
                typeButton="link"
                title="Volver"
                onClick={() => setOtpView(false)}
              />
            </div>
          </div>
          {!isMobile && <div className="f-otp__dog"></div>}
        </div>
      ) : (
        <div className="f-confirmation">
          <h2 className="f-title--purple f-confirmation__title">
            Confirma tu cuenta
          </h2>

          <i className="fal fa-exclamation-circle f-confirmation__icon"></i>

          <p className="f-confirmation__description">
            Necesitamos estar seguros que eres tú, confirma tu cuenta
          </p>

          <form
            onSubmit={handleSubmit(onSubmitConfirmation)}
            autoComplete="off"
          >
            <>
              {cellPhoneContact && (
                <p className="f-confirmation__option-description">
                  Enviaremos un código de 6 dígitos como mensaje de texto a tu
                  número de celular <b>{defaultData?.cellphone}</b>, ingresa el
                  código a continuación para confirmar tu cuenta y acceder a
                  Petys.
                </p>
              )}

              {emailContact && (
                <p className="f-confirmation__option-description">
                  Enviaremos un link a tu correo <b>{defaultData?.email}</b>{" "}
                  para confirmar tu cuenta y acceder a Petys.
                </p>
              )}
            </>

            <div className="f-buttons-container f-confirmation__buttons">
              {loader ? (
                <Loader
                  type="TailSpin"
                  color="#5C2D90"
                  height={50}
                  width={50}
                  className="f-loader"
                />
              ) : (
                <>
                  <Button
                    className="f-btn-purple f-button-prev"
                    // typeButton="button"
                    title="Atrás"
                    href="registro/paso-4"
                  />

                  <Button
                    className="f-primary-purple f-button-next"
                    typeButton="button"
                    type="submit"
                    title="Siguiente"
                    disabled={
                      emailContact === false && cellPhoneContact === false
                      // !formState.isValid
                    }
                  />
                </>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default ThirdStepContent
